.Product {
  container-type: inline-size;
  container-name: product;
}

.ProductHeadline {
  @media screen and (max-width: 767px) {
    @container product (width > 50vw) {
      /*
      apply larger font-size only on one column layout
      static value, as "@apply is not supported within nested at-rules"
      */
      font-size: 20px;
    }
  }
}

.BadgeText {
  @container product (width <= 200px) {
    display: none;
  }
}

.ProductPrices {
  flex-direction: column;
}
.ProductPrices > div {
  @container product (width >= 350px) {
    align-items: end;
  }
}

.ProductPrices > div > span {
  @container product (width <= 300px) {
    margin: 2px 0;
  }
}

.ProductPrices > div:first-of-type {
  @container product (width >= 350px) {
    margin-bottom: 3px;
  }
}
.MwstSpan {
  @container product (width <= 230px) {
    display: flex;
  }
}

.BadgeContainer {
  @container product (width <= 230px) {
    display: none;
  }
  display: flex;
}

.BadgeContainerViewSmall {
  @container product (width <= 230px) {
    display: flex;
  }
  display: none;
}
.BadgeContainerViewSmall > span {
  width: 100% !important;
  margin-bottom: 8px;
}
.RowWhenSmall {
  @container product (width <= 230px) {
    flex-direction: column !important;
    align-items: flex-start !important;
  }
}

.FlexWhenSmall {
  @container product (width <= 230px) {
    display: flex;
    align-items: baseline;
  }
}

.ContainerWidth {
  @container product (width <= 230px) {
    display: none;
  }
}
