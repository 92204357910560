.SeoText h1,
.SeoText h2 {
    margin-top: 64px;
    margin-bottom: 40px;
}

.SeoText h3,
.SeoText h4,
.SeoText h5,
.SeoText h6 {
    margin-top: 40px;
    margin-bottom: 24px;
}

.SeoText p {
    margin-bottom: 40px;
}

.SeoText h1:first-child,
.SeoText h2:first-child,
.SeoText h3:first-child,
.SeoText h4:first-child,
.SeoText h5:first-child,
.SeoText h6:first-child,
.SeoText p:first-child {
    margin-top: 0;
}

.SeoText h1:last-child,
.SeoText h2:last-child,
.SeoText h3:last-child,
.SeoText h4:last-child,
.SeoText h5:last-child,
.SeoText h6:last-child,
.SeoText p:last-child {
    margin-bottom: 0;
}