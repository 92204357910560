.Content a {
  @apply text-blue-light hover:text-blue-medium underline;
}
.Content > div > ul {
  list-style-type: disc;
  padding: 1rem 0 1rem 1.2rem;
}

.Content > div > ul > li {
  list-style-type: disc;
  padding: 0.5rem 0;
}
