.Button {
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-out  lg:text-2xl border-2 font-bold flex items-center justify-center;
}

.ButtonPrimary {
  @apply bg-orange-light text-white border-orange-light hover:bg-orange-medium hover:border-orange-medium disabled:bg-gray-300 disabled:border-gray-300;
}
.ButtonSecondary {
  @apply bg-blue-light text-white border-blue-light hover:bg-blue-medium hover:border-blue-medium disabled:bg-gray-300 disabled:border-gray-300;
}

.ButtonLink {
  @apply text-blue-light hover:text-blue-medium cursor-pointer bg-transparent font-bold !px-0 py-3 outline-none focus:outline-none  mr-1 mb-1 hover:!border-0 hover:bg-transparent !border-0 underline;
}

.ButtonHollow {
  @apply bg-transparent disabled:bg-transparent hover:bg-transparent;
}
.ButtonHollow.ButtonSecondary {
  @apply text-blue-light hover:text-blue-medium disabled:text-gray-300;
}
.ButtonHollow.ButtonPrimary {
  @apply text-orange-light hover:text-orange-medium disabled:text-gray-300;
}

.ButtonSmall {
  @apply px-7 py-3 text-sm leading-1;
}
.ButtonMedium {
  @apply px-7 py-5 text-base leading-2;
}
.ButtonLarge {
  @apply px-7 py-6 text-4xl leading-5;
}
.ButtonFullWidth {
  @apply w-full px-7 xl:py-6 py-4 xl:text-4xl text-xl leading-5;
}

.ButtonSmall svg {
  @apply w-5;
}
.ButtonMedium svg {
  @apply w-5;
}
.ButtonLarge svg {
  @apply w-7;
}
