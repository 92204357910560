.NavigationContainer {
  @apply flex w-full items-center justify-center flex-wrap xl:flex-nowrap fixed  !z-[60] top-0 overflow-hidden xl:overflow-auto;
  will-change: background;
}

.NavigationRoot {
  @apply w-full flex flex-wrap items-center order-last xl:order-none justify-start;
}
.NavigationRoot > div {
  @apply w-full;
}
.NavigationRoot > div {
  @apply lg:w-full;
}

.fade-in {
  opacity: 1;
  transform: scale(1);
}

.fade-out {
  opacity: 0;
  transform: scale(0.95);
}
