.JobStageUsps {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
}

.JobStageUsp {
    display: inline-block;
    padding: 4px 12px 4px 1px; /* 1px right to make a bit space for the border */
    background: #008CBA;
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    position: relative;
}

.JobStageUsp_Border {
    color: #008cba;
    position: absolute;
    height: 32px;
    right: 100%;
    margin-right: -1px;
    top: 0;
}

.JobStageUsp_Content {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: flex-start;
}

.JobStageUsp_Checkmark {
    margin-top: 2px; /* align checkmark with textcontent */
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 1280px) {
    .JobStageUsps {
        position: static;
        transform: none;
        background: #008CBA;
        color: white;
        align-items: flex-start;
        padding: 16px 20px;
        gap: 4px;
    }
    
    .JobStageUsp {
        padding: 0;
        background: transparent;
        color: inherit;
    }
    
    .JobStageUsp_Border {
        display: none;
    }
}

@media screen and (max-width: 1023px) {
    .JobStageUsp {
        padding: 10px 16px;
        font-size: 14px;
        line-height: 22px;
    }

    .JobStageUsp_Checkmark {
        margin-top: 3px; /* align checkmark with textcontent */
        width: 16px;
        height: 16px;
    }
}
