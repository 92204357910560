.TabStageSlide {
  @apply bg-gray-100 xl:h-[115px] h-[60px];
  padding: 8px 12px;
}
.TabStageSlide[data-state="active"] > div > p {
  @apply text-gray-100;
}
.TabStageSlide[data-state="active"] > div > div > span {
  @apply text-gray-100;
}

.TabStageSlide {
  @apply hover:!text-gray-100 before:block before:absolute before:bg-blue-medium before:rounded-lg sm:before:rounded-none rounded-lg sm:rounded-none w-full before:transition-all;
}
.TabStageSlide:before {
  content: "";
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  -webkit-transition: top 0.35s ease;
  transition: top 0.35s ease;
  will-change: top;
}

.TabStageSlide:hover:before {
  top: 0;
}

.TabStageSlide:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  font-size: 1.75em;
}

.Stage {
  @apply w-full bg-white relative flex justify-center items-end px-6 pt-6 mt-0 after:transition-all after:absolute;
}
/* .Stage > picture > img {
  width: 100%;
  height: 100%;
}
.Stage > picture {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */

.Stage:after {
  content: "";
  height: 30%;
  width: 100%;
  bottom: 0;
  left: 0;
}

.Slide > * > ul {
  @apply !flex flex-row md:flex-nowrap flex-wrap;
}
.Slide > * > ul > li {
  @apply lg:w-full w-1/2  lg:p-0 p-4;
}

.TabStage {
  @apply w-full flex relative items-center;
}

.TabStage > div:first-child {
  width: calc(100% - 9%);

  @media (min-width: 1536px) {
    width: calc(100% - 15%);
  }
}
@media (max-width: 767px) {
  .TabStage > div:first-child {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.TabStageTrigger {
  @apply flex flex-col justify-center items-center;
}

.TabStageFooter a {
  color: var(--blue-light, #008cba);
  text-decoration: underline;
}

.TabStageFooter a:hover {
  color: var(--blue-medium, #005e7c);
}
