.NavigationContainer {
  @apply flex w-full items-center justify-center flex-wrap xl:flex-nowrap fixed  !z-[60] top-0 overflow-hidden xl:overflow-auto;
  will-change: background;
}

.NavigationRoot {
  @apply w-full flex flex-wrap items-center order-last xl:order-none justify-start;
}
.NavigationRoot > div {
  @apply sm:w-full;
}
