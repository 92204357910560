.Pagination {
  @apply bg-transparent w-full p-6;
}
.Pagination button {
  @apply relative;
  width: 48px;
  height: 48px;
}
.Pagination button:after {
  @apply absolute h-3 bg-gray-300 top-1/2 left-1/2 -translate-x-1/2;
  width: 32px;
  top: 50%;
  content: " ";
}
.Pagination button[aria-selected="true"]:after {
  @apply bg-blue-medium;
}

@media screen and (max-width: 640px) {
  .Pagination li {
    display: none;
  }
}
